function isValidElement(element) {
  return 'value' in element && 'id' in element;
}

/**
 * @param element {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * @param msg {string}
 */
function showError(element, msg) {
  const span = element.parentElement.querySelector("span");
  if(span) {
    span.innerText = msg;
    element.parentElement.appendChild(span);
  }
  const message = element.parentElement.querySelector("label").innerText + " " + msg;
  throw new Error(message ?? "something went wrong");
}

/**
 * @param element {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * @return {boolean|null}
 */
function getNullableBoolean(element) {
  if (!isValidElement) throw new Error(`Not a valid element: ${element}`);
  if(element.value === "true") return true;
  if(element.value === "false") return false;
  return null;
}

/**
 * @param element {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * @return {boolean}
 */
function getBoolean(element) {
  if (!isValidElement) throw new Error(`Not a valid element: ${element}`);
  return element.value === "true";
}

/**
 * @param element {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * @return {number|null}
 */
function getNullableNumber(element) {
  if (!isValidElement) throw new Error(`Not a valid element: ${element}`);
  try {
    return parseInt(element.value, 10);
  } catch {
    return null;
  }
}

/**
 * @param element {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * @param min {number|null}
 * @param max {number|null}
 * @return {number}
 */
function getNumber(element, min=null, max=null) {
  if (!isValidElement) throw new Error(`Not a valid element: ${element}`);
  let val = 0;
  try {
    val = parseInt(element.value, 10);
  } catch {
    showError(element, `Element with id ${element.id} has a value of ${element.value} which is not valid`);
  }

  if(max == null && min == null) return val;
  if(max != null && val > max) showError(element, `Maximum value is ${max}`);
  if (min != null && val < min) showError(element, `Minimum value is ${min}`);
  return val;
}

/**
 * @param element {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * @param minLength {number}
 * @param maxLength {number}
 * @return {string}
 */
function getString(element, minLength = 2, maxLength=60) {
  if (!isValidElement(element)) throw new Error(`Not a valid element: ${element}`);
  if (element.value.length < minLength) showError(element, `value must be at least ${minLength} characters.`);
  if (element.value.length > maxLength) showError(element,  `value must be less than ${maxLength} characters.`);
  return element.value;
}

/**
 * @param element {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * @param maxLength {number}
 */
function getNullableString(element, maxLength=-1) {
  if (!isValidElement) throw new Error(`Not a valid element: ${element}`);
  if(element.value === "") return null;
  if(maxLength > 0 && element.value.length > maxLength) {
    showError(element, `form field has max character length of ${maxLength}`)
  }
  return element.value;
}

export default {
  getNumber,
  getBoolean,
  getString,
  getNullableNumber,
  getNullableBoolean,
  getNullableString
}
