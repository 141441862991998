/** theTestimony/js/index.js
 * This is the master javascript file that entire site pulls on every page
 * This file mostly pull theme related styles
 */

/**
 * Imports the default uikit theme
 */
import '../less/sample.less';

/**
 * Imports fontawesome
 */
import '@fortawesome/fontawesome-free/less/fontawesome.less';
import '@fortawesome/fontawesome-free/js/all';


/**
 * Imports UIKit javascript and makes it globally available via the window object
 */
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import NiceSelect from 'nice-select2/dist/js/nice-select2';
window.NiceSelect = NiceSelect;
/**
 * import custom theme and uikit theme overrides
 */
import '../less/main.less';

/**
 * import custom javascript
 */
import forms from "./forms";

UIkit.use(Icons);
window.UIkit = UIkit;
window.$testimony = {
  ...forms
}
